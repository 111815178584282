import _ from 'lodash'

export default {
  sortByFormatted (format, collection, attributes) {
    let sortIteratees = []
    // For each attribute check if it is shown in the textFormat, and if so add it to the list of sorters
    attributes.map((attr) => {
      if (!format || format.includes(attr)) {
        // If the code is displayed, sort by the code first
        sortIteratees.push((item) => {
          if (!isNaN(item[attr])) {
            // If it is a number, sort it as such
            return parseInt(item[attr])
          } else {
            // Treat as string, so use uppercased to compare
            if (typeof item[attr] === 'string') {
              return item[attr].toUpperCase()
            } else {
              return ''
            }
          }
        })
      }
    })
    return _.sortBy(collection, sortIteratees)
  },

  naturalSort (as, bs) {
    var a; var b; var a1; var b1; var i = 0; var L; var rx = /(\d+)|(\D+)/g; var rd = /\d/
    if (isFinite(as) && isFinite(bs)) return as - bs
    a = String(as).toLowerCase()
    b = String(bs).toLowerCase()
    if (a === b) return 0
    if (!(rd.test(a) && rd.test(b))) return a > b ? 1 : -1
    a = a.match(rx)
    b = b.match(rx)
    L = a.length > b.length ? b.length : a.length
    while (i < L) {
      a1 = a[i]
      b1 = b[i++]
      if (a1 !== b1) {
        if (isFinite(a1) && isFinite(b1)) {
          if (a1.charAt(0) === '0') a1 = '.' + a1
          if (b1.charAt(0) === '0') b1 = '.' + b1
          return a1 - b1
        } else return a1 > b1 ? 1 : -1
      }
    }
    return a.length - b.length
  },

  anotherNaturalSort (a, b) {
    if (typeof a === 'undefined' || a === null) {
      return 1
    }
    return a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
  }
}
